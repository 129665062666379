.ant-drawer.project-settings {
  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .setting-group {
    &:not(:first-child) {
      border-top: 1px solid #ccc;
      margin-top: 2rem;
      padding-top: 1.5rem;
    }

    .setting-group-heading {
      margin-bottom: 0.25rem;
    }
  }

  .spacer {
    flex: 1;
  }

  .ant-select {
    width: 100%;

    .ant-select-item {
      width: min-content;
    }
  }

  .collaborators {
    .ant-empty {
      margin: 0;
    }

    .ant-empty-image {
      height: 32px;
    }

    .add-collaborator {
      width: 100%;
      margin-top: 0.5rem;
    }

    .collaborator-entry {
      display: flex;
      padding: 0.25rem 0.25rem 0.25rem 1rem;

      .invite {
        margin-left: -0.25rem;
        margin-right: 0.5rem;
        color: #999;
      }

      .display-name {
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        flex: 1;
      }

      .ant-btn {
        padding: 0.25rem 0.5rem;
      }
    }
  }

  .download-project-buttons {
    display: flex;

    button.download-project {
      flex: 1;
      height: unset;

      p {
        margin: 0;
        text-transform: uppercase;
        font-weight: bold;
        color: #666;
      }

      &:not(:last-child) {
        margin-right: 0.5rem;
      }
    }
  }
}

.duplicate-file-error {
  p {
    margin-bottom: 0;
  }

  ul {
    margin-bottom: 0;
    padding-left: 1rem;

    background-color: #ddd;
    border-radius: 0.25rem;
    margin: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    text-align: left;

    li {
      list-style: none;

      code {
        background-color: #fff;
        padding: 0.125rem 0.375rem;
        border-radius: 0.125rem;
        margin-left: 0.125rem;
        margin-right: 0.125rem;
      }
    }
  }
}

.ant-modal {
  .ant-select {
    width: 100%;
  }
}