.sidebar {
  background-color: #fff;

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
  }

  .ant-divider-horizontal {
    margin: 0;
    margin-bottom: 0.5rem;
  }

  .files-menu {
    width: 100%;

    .title-row {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding: 0.25rem;

      .icon-area > * {
        margin-right: 0.25rem;
        width: 32px;
        padding: 0;
      }

      .files-title {
        text-transform: uppercase;
        font-weight: bold;
        color: #666;
        padding: 0.5rem 0.75rem 0;
      }
    }
  }
  .ant-tree-node-content-wrapper {
    display: flex;
    align-items: center;
    overflow: hidden;

    .ant-tree-title {
      display: flex;
      flex: 1;
      padding-top: 0.2rem;
      padding-bottom: 0.2rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
    }

    input {
      display: inline;
      flex: 1;
      padding: 0 0.25rem;
      border: 1px solid black;
      text-align: right;
      box-sizing: border-box;
    }
  }
}

.ant-modal.resource-preview .ant-modal-body {
  text-align: center;

  img {
    max-width: 100%;
    max-height: 50vh;
    object-fit: contain;
  }
}
