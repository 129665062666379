#workspace {
  width: calc(100% - 2rem);
  max-width: 1000px;
  margin: 2rem auto 0 auto;

  h1 {
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    white-space: nowrap;
    margin-bottom: 0;
  }

  .workspace-author {
    font-size: 1.2rem;
    font-weight: normal;
    margin-left: 0.5em;
  }
}

.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
