body {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

#app {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.loading {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
