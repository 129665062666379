* + .input-label {
  margin-top: 1.5rem;
}

.input-label {
  margin-bottom: 0.25rem;
}

.input-label + input {
  margin-top: 0;
}

.project-select {
  display: flex;
  flex-direction: row;

  p {
    flex-shrink: 1;
  }

  p + p {
    margin-left: 1rem;
  }

  .workspace-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #aaa;
    overflow: hidden;
    direction: rtl;
    font-size: 0.9em;
  }
}
