.editor {
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  background-color: #1e1e1e;
  transition: 0.5s flex-basis cubic-bezier(0.075, 0.82, 0.165, 1);

  &.blank {
    flex-basis: 0;
  }

  .file-not-found {
    text-align: center;
    color: #eee;
  }

  .monaco-wrapper {
    flex: 1;
    flex-basis: 0;
    overflow: hidden;
  }
}
