input.project-name {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  width: 40%;
  height: 2rem;
  padding: 0.25rem;
  border: none;
  border-radius: 0.125rem;

  background: none;
  color: rgba(255, 255, 255, 0.65);
  font-size: 1.2rem;
  text-align: center;
  font-weight: bold;
  &:focus {
    border: 1px solid rgba(255, 255, 255, 0.65);
  }
}
