header.ant-layout-header {
  $header-height: 2.5rem;

  height: $header-height;
  line-height: $header-height;

  padding: 0;

  display: flex;
  flex-direction: row;

  > div {
    width: calc(100% - 2rem);
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin: auto;

    &.narrow {
      max-width: 1000px;
    }
  }

  ul.ant-menu {
    height: $header-height;
    line-height: $header-height;

    flex: 1;

    li.ant-menu-item {
      height: $header-height;
      line-height: $header-height;
    }
  }
}

.user-box {
  color: rgba(255, 255, 255, 0.65);
  display: flex;
  align-items: center;
  & > *:not(:first-child) {
    margin-left: 1rem;
  }
  p {
    margin-bottom: 0;
  }
  button.sign-in-with-github,
  button.sign-out {
    background-color: #24292e;
    color: rgba(255, 255, 255, 0.65);
  }
}
