.output {
  position: relative;
  flex: 1;
  background-color: #eee;
  position: relative;
  padding: 16px;
  padding-bottom: 0;
  overflow-x: hidden;
  overflow-y: scroll;

  &.has-content {
    background-color: #666;
  }

  .rendered-output {
    transform-origin: top left;
  }

  p.no-content {
    width: fit-content;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    user-select: none;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    padding: 1rem;
    border-radius: 0.5rem;
    color: rgba(0, 0, 0, 0.4);
    background-color: rgba(0, 0, 0, 0.1);
  }

  .ant-result-error {
    ul {
      list-style-type: none;
      li {
        padding-left: 0;
      }
    }

    .error-marker {
      color: red;
      margin-right: 0.5rem;
    }
  }
}

.loading-indicator {
  display: flex;
  position: absolute;
  bottom: 2rem;
  right: 3rem;
  height: fit-content;
  background-color: #eee;
  border-radius: 0.5rem;
  box-shadow: 0.25rem 0.25rem 0.5rem #333;

  width: 3rem;
  height: 3rem;

  align-items: center;
  justify-content: center;

  span.anticon {
    font-size: 2rem;
  }

  opacity: 0;
  &.loading {
    opacity: 1;
  }
}

.pagedjs_page_content > * {
  columns: 2;
}

.wide {
  column-span: all;
}

@media print {
  .output,
  .render-wrapper,
  .rendered-output {
    width: fit-content !important;
    height: fit-content !important;
    margin-bottom: 0 !important;
  }
}
