#projects,
#workspace {
  h1 {
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    white-space: nowrap;
    margin-bottom: 0;
  }
}

#projects,
#workspace {
  width: calc(100% - 2rem);
  max-width: 1000px;
  margin: 2rem auto 0 auto;
}

.ant-row.title-row {
  justify-content: space-between;
  align-items: center;
}

#projects .ant-empty {
  margin: 0;
  .ant-empty-image {
    height: 32px;
  }
}

.draggable-project {
  transition: width 0.2s;
}

.project-item,
.workspace-item {
  border: 1px solid #d9d9d9 !important;
  background-color: #fff;
  border-radius: 2px;

  margin: 0 0 6px 0;
  padding: 0;

  .item-icon {
    font-size: 1.3em;
    margin-right: 12px;
  }

  .link {
    display: flex;
    align-items: center;

    width: 100%;
    padding: 6px 12px;

    color: black;
    font-weight: bold;
    flex: 1;
    min-height: 44px;
  }

  .ant-card {
    width: 100%;
  }

  .ant-card-head-wrapper {
    width: 100% !important;
    display: flex;
  }
  .ant-card-head,
  .ant-card-head-title {
    padding: 0;
    display: flex;
    font-size: 1em;
  }
  .ant-card-body {
    padding: 12px;
  }

  .owner {
    font-weight: normal;
    margin-left: 0.3em;
  }

  .space {
    flex: 1;
  }

  .ant-btn.success {
    color: #090;
  }
}

.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
